@font-face {
    font-face-name: Bitter;
    src: url("./assets/Bitter-VariableFont_wght.ttf");
}

.main-container {
    text-align: center;
    font-family: Bitter, serif;
    color: #fa2020;
}

.nav-strip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 94.6vw;
    margin: 1.2vh 2.7vw;
}

.nav-logo {
    border: solid #fa2020 .33vh;
    border-radius: 50%;
    width: 6vw;
}

.nav-link {
    text-decoration: none;
    color: #fa2020;
}

.nav-text {
    font-size: 4.2vh;
}

.title-container {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 18vh;
    animation: title-animation 2.7s infinite alternate;
}

@keyframes title-animation {
    from {
        font-size: 18vh;
        padding-top: 0;
    }
    to {
        font-size: 15vh;
        padding-top: 2.7vh;
    }
}

.maochi-container {
    margin-top: 3vh;
    height: 54vh;
    border: solid #fa2020 1.2vh;
    border-radius: 6%;
}

.description-container {
    height: 9vh;
    margin: 6vh 27vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffb2b2;
}

.description {
    font-size: 5.4vh;
}

.contract {
    font-size: 3vh;
}

.info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 9vh;
    margin: 6vh 32vw;
}

.buy-button {
    font-size: 3vh;
    font-family: Bitter, serif;
    height: 7.5vh;
    width: 15vw;
    margin-right: 3vw;
    border-radius: 1.5vh;
    border: solid #fa2020 0.66vh;
    background-color: #ffb2b2;
    color: #fa2020;
}

.buy-button:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.socials-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 18vw;
}

.social-button {
    height: 9vh;
}

.social-button:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.social-image {
    height: 9vh;
}

.social-image:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.meme-text {
    font-size: 6vh;
}

.meme-container {
    height: 20vw;
    width: 60vw;
    margin: 0 20vw;
    display: flex;
    flex-direction: row;
}

.meme-image {
    height: 20vw;
    width: 20vw;
}